<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import btnLink from '../ui/btnLink.vue';
import axios from 'axios';
import Tr from '@/i18n/translation';

export default {
  name: 'HomeActionBlock',
  components: {
    Swiper,
    SwiperSlide,
    btnLink,
  },
  props: {
    msg: String,
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper3 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper3,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },

  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      news: [],
      timeline: null,
      Tr: Tr,
    };
  },
  computed: {
    localeUp() {
      return this.$i18n.locale;
    },
  },
  watch: {
    localeUp() {
      this.getNews();
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.timeline = gsap.timeline();
    this.timeline.fromTo(
      '.news',
      { y: '50%', opacity: 0.2 },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: '.news',
          start: 'top bottom',
          end: 'top center',
          scrub: 2,
        },
      }
    );

    this.getNews();
  },
  methods: {
    getNews() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}&id=2&locale=${this.$i18n.locale}`;

      axios
        .get(this.apiUrl + 'api/blog/get-all-by-category' + params)
        .then((response) => {
          console.log('get-news', response.data.blog);
          this.news = response.data.blog;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remoteText(text) {
      if (!text) return '';
      // Удаляем HTML-теги с помощью регулярного выражения
      const plainText = text.replace(/<\/?[^>]+(>|$)/g, '');

      // Обрезаем текст до 100 символов и добавляем многоточие
      return plainText.length > 100
        ? plainText.substring(0, 100) + '...'
        : plainText;
    },
    timestampToDate(timestamp) {
      if (timestamp === null || timestamp === undefined || !timestamp) {
        return;
      }

      const date = new Date(timestamp * 1000);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
  },
};
</script>
<template>
  <div class="news sector">
    <div class="container">
      <div class="news__content">
        <div class="news__head">
          <div v-if="msg" class="head-h3">События</div>
          <div v-else class="head-h3">
            {{ $t('home.home-news-block.title') }}
          </div>

          <btnLink v-if="!msg" :link="'News'">{{
            $t('home.home-news-block.btn')
          }}</btnLink>
        </div>
        <div class="arrows">
          <a class="arrow-prev arrow2-prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M13.75 17.4805L7.26819 10.9987L13.75 4.51684"
                stroke="#111111"
                stroke-width="1.28333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <a class="arrow-next arrow2-next"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M8.25 4.51953L14.7318 11.0013L8.25 17.4832"
                stroke="#111111"
                stroke-width="1.28333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <swiper
          :slides-per-view="'auto'"
          :space-between="20"
          :modules="modules"
          :navigation="{
            enabled: true,
            prevEl: '.arrow2-prev',
            nextEl: '.arrow2-next',
          }"
          :breakpoints="{
            1600: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
          }"
          style="width: 100%"
          @swiper="onSwiper3"
        >
          <swiper-slide
            v-for="action in news"
            :key="action.id"
            class="news__item"
          >
            <router-link :to="{ name: 'PostPage', params: { id: action.id } }">
              <div class="news__pic">
                <img
                  v-if="action.pic != null && action.pic != 'null'"
                  :src="apiDomain + `/web/uploads/` + action.pic"
                  :alt="action.head"
                />
              </div>
              <div class="news__attributes">
                <div class="date">{{ timestampToDate(action?.date_add) }}</div>

                <div class="head-news">{{ action.title }}</div>
                <div class="excerpt">{{ remoteText(action.text) }}</div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<style scoped>
.news.sector {
  overflow: hidden;
}
.arrows {
  display: flex;
  align-items: center;
  gap: 18px;
}
.news__content {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.news__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news__item {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 622px;
}
.swiper-slide {
  width: max-content;
}
.news__item .news__pic {
  height: 396px;
  width: 622px;
}
.news__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news__attributes {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.date {
  color: var(--Black-1200, #2e2e2e);
  font-family: 'Suisse Intl';
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 14px;
}
.head-news {
  color: var(--Black, #111);
  font-family: 'Suisse Intl';
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.excerpt {
  overflow: hidden;
  color: var(--Black-1100, #434343);
  text-overflow: ellipsis;
  font-family: 'Suisse Intl';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 571px;
  width: 100%;
}
.swiper {
  width: 120%;
  flex-shrink: 0;
  overflow: visible;
}
@media screen and (max-width: 1440px) {
  .news__item .news__pic {
    width: 522px;
    height: 332px;
  }
  .swiper {
    overflow: hidden;
  }
}
@media screen and (max-width: 1280px) {
  .news__pic {
    max-width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .news__content {
    gap: 24px;
  }
  .news__item .news__pic {
    height: 162px;
  }
  .date {
    font-size: 10px;
  }
  .head-news {
    font-size: 12px;
  }
  .excerpt {
    font-size: 10px;
  }
}
</style>
