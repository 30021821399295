<script>
import btnLink from '../ui/btnLink.vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import axios from 'axios';
import Tr from '@/i18n/translation';

export default {
  name: 'HomeSpecialBlock',
  components: {
    btnLink,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      timeline: null,
      banner: null,
      Tr: Tr,
    };
  },
  computed: {
    localeUp() {
      return this.$i18n.locale;
    },
  },
  watch: {
    localeUp() {
      this.getBanner();
    },
  },
  mounted() {
    this.getBanner();
    gsap.registerPlugin(ScrollTrigger);
    this.timeline = gsap.timeline();
    this.timeline.fromTo(
      '.home-special-block',
      { y: '50%', opacity: 0.2 },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: '.home-special-block',
          start: 'top bottom',
          end: 'top center',
          scrub: 2,
        },
      }
    );
  },
  methods: {
    getBanner() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(
          this.apiUrl +
            'api/banner/get-banner-training' +
            params +
            '&locale=' +
            this.$i18n.locale
        )
        .then((response) => {
          this.banner = response.data.banner[0];
          console.log('banners', this.banner);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSlideStyle(imgUrl) {
      return {
        background:
          'url(' + this.apiDomain + `/web/uploads/${imgUrl}) no-repeat`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      };
    },
  },
};
</script>

<template>
  <div
    v-if="banner"
    :style="getSlideStyle(banner?.pic)"
    class="home-special-block sector-mt"
  >
    <div class="container">
      <div class="home-special-block__content">
        <div class="home-special-block__head">
          <div class="head-social">{{ banner?.title }}</div>
          <p class="description">
            {{ banner?.subtitle }}
          </p>
          <btnLink
            :link="'Training'"
            :class-name="'mix'"
            style="margin-top: 24px; width: max-content"
            >{{ $t('home.home-special-block.btn') }}</btnLink
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-special-block {
  background-position: top right;
  background-color: #eaeaea;
  padding: 99px 0;
}
.home-special-block__content {
  display: flex;
  flex-direction: column;
  grid-template: 20px;
}
.head-social {
  color: var(--Black, #fff);
  font-family: 'Suisse Intl';
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 683px;
  mix-blend-mode: difference;
}
.description {
  max-width: 460px;
  mix-blend-mode: difference;
  color: var(--Black, #fff);
}
.home-special-block__head {
  display: flex;
  flex-direction: column;
  gap: 20px;
  mix-blend-mode: difference;
}

@media screen and (max-width: 1024px) {
  .head-social {
    font-size: 26px;
    max-width: 339px;
  }
  .description {
    max-width: 358px;
  }
  .home-special-block {
    padding: 36px 0;
    background-size: 78%;
    background-position: 161% 37%;
  }
  .home-special-block__head {
    gap: 12px;
  }
}
@media screen and (max-width: 650px) {
  .head-social {
    font-size: 17px;
    max-width: 211px;
  }
  .home-special-block__head {
    gap: 10px;
  }
  .description {
    max-width: 211px;
  }
  .home-special-block {
    padding: 29px 0;
    background-size: 120%;
    background-position: -177% 40%;
  }
}
</style>
