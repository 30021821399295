<script>
import { useCookies } from 'vue3-cookies';
import axios from 'axios';
import WidjetCart from './components/ui/WidjetCart.vue';
import CookiesAccept from './components/CookiesAccept.vue';
export default {
  name: 'App',
  components: {
    WidjetCart,
    CookiesAccept,
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      cartProduct: false,
      message: false,
      isCookiesAccepted: true,
    };
  },
  mounted() {
    if (localStorage.getItem('isCookiesAccepted')) {
      this.isCookiesAccepted = JSON.parse(
        localStorage.getItem('isCookiesAccepted')
      );
    } else {
      localStorage.setItem('isCookiesAccepted', JSON.stringify(false));
      this.isCookiesAccepted = false;
    }
    this.checkCookie();
    if (window.innerWidth > 1440 && window.innerWidth < 1920) {
      document.querySelector('body').classList.add('body1440');
    }
    setInterval(() => {
      let addCart = localStorage.getItem('addCart');
      let addWishlistNot = localStorage.getItem('addWishlistNot');
      let addWishlist = localStorage.getItem('addWishlist');
      if (addWishlist && !addWishlistNot && !addCart) {
        this.cartProduct = true;
        this.message = 'addWishlist';
        return;
      } else {
        this.cartProduct = false;
      }
      if (addWishlistNot && !addWishlist && !addCart) {
        this.message = 'addWishlistNot';

        this.cartProduct = true;
        return;
      } else {
        this.cartProduct = false;
      }
      if (addCart && !addWishlistNot && !addWishlist) {
        this.message = 'cart';
        this.cartProduct = true;
        return;
      } else {
        this.cartProduct = false;
      }
    }, 50);
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('isCookiesAccepted', JSON.stringify(true));
      this.isCookiesAccepted = true;
    },
    checkCookie() {
      let user = this.cookies.isKey('user') ? this.cookies.get('user') : false;
      console.log('UserAPP', user);
      if (user) {
        this.$store.commit('setUserData', user);
        this.getWishlist();
      }
      let cartCount = localStorage.getItem('cartCount');
      if (cartCount) {
        this.$store.commit('setCount', cartCount);
      }
      this.getProducts();
    },
    getProducts() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(`${this.apiUrl}api/product/get-product${params}`)
        .then((response) => {
          console.log('get-product', response.data);

          this.$store.commit('setProducts', response.data.products);
        });
    },
    getWishlist() {
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(
          this.apiUrl +
            'api/user-favorites/get-by-user-id' +
            authGet +
            `&user_id=${this.user.user_id}`
        )
        .then((response) => {
          console.log('getWishlist', response);
          let favorites = [];
          for (let f of response.data.favorites) {
            let index = favorites.findIndex(
              (item) => item.product_id === f.product_id
            );
            if (index === -1) {
              favorites.push(f);
            } else {
              favorites[index] = f;
            }
          }
          this.$store.commit('setFavorites', favorites);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <router-view></router-view>
  <WidjetCart v-if="cartProduct" :msg="message" />
  <Transition name="cookies-slide">
    <CookiesAccept v-if="!isCookiesAccepted" @accept="acceptCookies" />
  </Transition>
</template>

<style>
.cookies-slide-enter-active,
.cookies-slide-leave-active {
  transition: transform 0.7s ease;
}

.cookies-slide-enter-from,
.cookies-slide-leave-to {
  transform: translateY(500px);
}
</style>
