<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import btnLink from '../ui/btnLink.vue';
export default {
  name: 'HomeActionBlock',
  components: {
    Swiper,
    SwiperSlide,
    btnLink,
  },
  props: {
    msg: String,
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper2 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper2,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },

  data() {
    return {
      actions: [
        {
          id: 1,
          imgUrl: require('@/assets/image/home/action-1.png'),
          head: 'PDNT OCTO LONG',
          percent: 10,
        },
        {
          id: 2,
          imgUrl: require('@/assets/image/home/action-2.png'),
          head: 'AERO SPOT',
          percent: 20,
        },
        {
          id: 3,
          imgUrl: require('@/assets/image/home/action-3.png'),
          head: 'PDNT STICK',
          percent: 30,
        },
        {
          id: 4,
          imgUrl: require('@/assets/image/home/action-4.png'),
          head: 'PDNT STICK',
          percent: 40,
        },
        {
          id: 5,
          imgUrl: require('@/assets/image/home/action-5.png'),
          head: 'AERO SPOT',
          percent: 50,
        },
      ],
      timeline: null,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  computed: {
    products() {
      return this.$store.getters.getProducts.filter(
        (product) => product.sale !== null
      );
    },
  },
  mounted() {
    if (!this.msg) {
      gsap.registerPlugin(ScrollTrigger);
      this.timeline = gsap.timeline();
      this.timeline.fromTo(
        '.action',
        { y: '50%', opacity: 0.2 },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: '.action',
            start: 'top bottom',
            end: 'top center',
            scrub: 2,
          },
        }
      );
    }
  },
  methods: {
    truncate(text, maxLength) {
      let mobile = window.innerWidth < 1024;
      if (mobile) {
        maxLength = 28;
      }
      if (!text) return '';
      return text.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text;
    },
  },
};
</script>

<template>
  <div class="action sector">
    <div class="container">
      <div class="action__content">
        <div class="action__head">
          <div v-if="msg" class="head-h3">{{ msg }}</div>
          <div v-else class="head-h3">
            {{ $t('home.home-action-block.title') }}
          </div>
          <btnLink v-if="!msg" :link="'Actions'">{{
            $t('home.home-action-block.btn')
          }}</btnLink>
        </div>
        <swiper
          :slides-per-view="4"
          :space-between="20"
          :breakpoints="{
            1600: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
          }"
          :modules="modules"
          style="width: 100%"
          @swiper="onSwiper2"
        >
          <swiper-slide
            v-for="action in products"
            :key="action.id"
            class="action__item"
          >
            <router-link
              :to="{
                name: 'ProductPage',
                params: { id: action?.group?.group_id || action?.id },
                query: { p: action?.group?.group_id ? undefined : 'true' },
              }"
              class="action__item"
            >
              <div class="action__item-img">
                <img
                  :src="apiDomain + `/web/uploads/` + action.productPhoto[0]"
                  :alt="action.name"
                  loading="lazy"
                />
                <div class="tag-action">
                  -{{ Number(action?.sale) > 0 ? action?.sale : 0 }}%
                </div>
              </div>
              <div :title="action.name" class="head-h4">
                {{ truncate(action.name, 30) }}
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action__content {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.action__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action__item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.action__item:hover .action__item-img img {
  transform: scale(1);
  transition: all 0.3s;
}
.action__item-img img {
  transform: scale(1.1);
  transition: all 0.3s;
}
.action__item-img {
  overflow: hidden;
}
.action__item-img {
  position: relative;
  min-height: 360px;
}
.action__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
.tag-action {
  background: url(@/assets/image/home/bg-tag.svg) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 26px 4px 10px;
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1024px) {
  .action__item-img {
    min-height: 228px;
  }
}
@media screen and (max-width: 650px) {
  .action__content {
    gap: 24px;
  }
}
</style>
