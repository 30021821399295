<script>
import HeaderBlock from '../block/HeaderBlock.vue';

export default {
  name: 'ErrorPage',
  components: {
    HeaderBlock,
  },
};
</script>

<template>
  <HeaderBlock />
  <div class="error home-main">
    <div class="imgBlock">
      <img class="errorImg" src="@/assets/image/svet.png" alt="error" />
      <img class="errorImg2" src="@/assets/image/svet1.svg" alt="error1" />
    </div>
    <div class="error__content">
      <div class="textBlock">
        <h4>Ошибка</h4>
        <h1>404</h1>
        <p>Страница не найдена</p>
      </div>
      <a href="/shop" title="Сраница в разработке" class="btn-opacity">
        Вернуться на главную
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M5.25 5.25H12.75M12.75 5.25V12.75M12.75 5.25L5.25 12.75"
            stroke="white"
            stroke-width="1.35"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    </div>
  </div>
</template>
<style scoped>
.error {
  background: var(--Black, #111);
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.imgBlock {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-13px);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: rotate 5s linear infinite;
  transform-origin: top center;
  transition: all 0.2s;
}
@keyframes rotate {
  0% {
    transform: translateX(-50%) translateY(-13px) rotate(0deg);
  }
  25% {
    transform: translateX(-50%) translateY(-13px) rotate(-7deg);
  }
  50% {
    transform: translateX(-50%) translateY(-13px) rotate(0deg);
  }
  75% {
    transform: translateX(-50%) translateY(-13px) rotate(7deg);
  }
  100% {
    transform: translateX(-50%) translateY(-13px) rotate(0deg);
  }
}
.errorImg {
  max-width: 388px;
}
.errorImg2 {
  margin-top: -77px;
  width: 1106px;
  transform: translateX(-13px);
  max-width: none;
}
h1 {
  font-family: 'Suisse Intl';
  font-size: 206px;
  font-style: normal;
  font-weight: 450;
  line-height: 0.9;
  background: linear-gradient(180deg, #ede0d0 0%, #101010 109.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h4 {
  color: #c6bbae;
  font-family: 'Suisse Intl';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 0.9;
  background: linear-gradient(180deg, #ede0d0 0%, #101010 109.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: right;
}
p {
  background: linear-gradient(180deg, #ede0d0 -42.86%, #101010 123.21%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Suisse Intl';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.btn-opacity {
  border: 1px solid var(--White, #fff);
  padding: 16.5px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 288px;
  margin-top: 12px;
}
.error__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 63px;
  height: 393px;
  position: relative;
  z-index: 2;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media screen and (max-width: 650px) {
  .errorImg {
    max-width: 260px;
  }
  .errorImg2 {
    margin-top: -49px;
    width: 722px;
    transform: translateX(-8px);
    max-width: none;
  }
  h1 {
    font-size: 120px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 20px;
  }
  .btn-opacity {
    font-size: 12px;
    padding: 12px;
    width: 184px;
  }
  .error__content {
    gap: 30px;
  }
}
</style>
