<template>
  <div class="language-switcher">
    <button class="language-button" @click.prevent="toggleDropdown">
      <span>
        <svg
          v-if="currentLocale === 'ru'"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clip-path="url(#clip0_2465_26783)">
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#F0F0F0"
            />
            <path
              d="M15.5024 10.783C15.824 9.9163 16 8.97893 16 8.00037C16 7.0218 15.824 6.08443 15.5024 5.21777H0.497594C0.176031 6.08443 0 7.0218 0 8.00037C0 8.97893 0.176031 9.9163 0.497594 10.783L8 11.4786L15.5024 10.783Z"
              fill="#0052B4"
            />
            <path
              d="M7.99996 15.9996C11.4397 15.9996 14.372 13.8286 15.5024 10.7822H0.497559C1.6279 13.8286 4.56025 15.9996 7.99996 15.9996Z"
              fill="#D80027"
            />
          </g>
          <defs>
            <clipPath id="clip0_2465_26783">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <svg
          v-else-if="currentLocale === 'en'"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clip-path="url(#clip0_2465_26815)">
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#F0F0F0"
            />
            <path
              d="M1.65373 3.12891C1.02532 3.9465 0.55148 4.88884 0.275574 5.91256H4.43739L1.65373 3.12891Z"
              fill="#0052B4"
            />
            <path
              d="M15.7244 5.91253C15.4485 4.88884 14.9747 3.9465 14.3463 3.12891L11.5627 5.91253H15.7244Z"
              fill="#0052B4"
            />
            <path
              d="M0.275574 10.0869C0.551511 11.1106 1.02535 12.0529 1.65373 12.8705L4.43729 10.0869H0.275574Z"
              fill="#0052B4"
            />
            <path
              d="M12.8705 1.65358C12.0529 1.02517 11.1106 0.551328 10.0869 0.275391V4.43717L12.8705 1.65358Z"
              fill="#0052B4"
            />
            <path
              d="M3.12939 14.3461C3.94699 14.9745 4.88933 15.4483 5.91302 15.7242V11.5625L3.12939 14.3461Z"
              fill="#0052B4"
            />
            <path
              d="M5.91299 0.275391C4.8893 0.551328 3.94696 1.02517 3.12939 1.65355L5.91299 4.43714V0.275391Z"
              fill="#0052B4"
            />
            <path
              d="M10.087 15.7242C11.1107 15.4483 12.053 14.9745 12.8706 14.3461L10.087 11.5625V15.7242Z"
              fill="#0052B4"
            />
            <path
              d="M11.5626 10.0869L14.3462 12.8705C14.9746 12.053 15.4485 11.1106 15.7244 10.0869H11.5626Z"
              fill="#0052B4"
            />
            <path
              d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z"
              fill="#D80027"
            />
            <path
              d="M10.087 10.0869L13.6569 13.6568C13.821 13.4927 13.9777 13.3211 14.1271 13.1432L11.0708 10.0869H10.087V10.0869Z"
              fill="#D80027"
            />
            <path
              d="M5.91305 10.0869H5.91298L2.34314 13.6568C2.50726 13.8209 2.67886 13.9776 2.85673 14.127L5.91305 11.0706V10.0869Z"
              fill="#D80027"
            />
            <path
              d="M5.91293 5.91274V5.91268L2.34305 2.34277C2.17887 2.5069 2.02224 2.67849 1.8728 2.85637L4.92915 5.91271L5.91293 5.91274Z"
              fill="#D80027"
            />
            <path
              d="M10.087 5.9132L13.6569 2.34327C13.4928 2.17908 13.3212 2.02245 13.1433 1.87305L10.087 4.92939V5.9132Z"
              fill="#D80027"
            />
          </g>
          <defs>
            <clipPath id="clip0_2465_26815">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {{ $t(`locale.${currentLocale}`) }}
        <svg
          class="arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="4"
          viewBox="0 0 6 4"
          fill="none"
          :class="{ rotated: isDropdownVisible }"
        >
          <path d="M3 4L6 0L0 0L3 4Z" fill="white" />
        </svg>
      </span>
    </button>

    <div v-if="isDropdownVisible" class="language-dropdown">
      <button
        v-for="sLocale in otherLocales"
        :key="`locale-${sLocale}`"
        @click.prevent="switchLanguage(sLocale)"
      >
        <span>
          <svg
            v-if="sLocale === 'ru'"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_2465_26783)">
              <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#F0F0F0"
              />
              <path
                d="M15.5024 10.783C15.824 9.9163 16 8.97893 16 8.00037C16 7.0218 15.824 6.08443 15.5024 5.21777H0.497594C0.176031 6.08443 0 7.0218 0 8.00037C0 8.97893 0.176031 9.9163 0.497594 10.783L8 11.4786L15.5024 10.783Z"
                fill="#0052B4"
              />
              <path
                d="M7.99996 15.9996C11.4397 15.9996 14.372 13.8286 15.5024 10.7822H0.497559C1.6279 13.8286 4.56025 15.9996 7.99996 15.9996Z"
                fill="#D80027"
              />
            </g>
            <defs>
              <clipPath id="clip0_2465_26783">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg
            v-else-if="sLocale === 'en'"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_2465_26815)">
              <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#F0F0F0"
              />
              <path
                d="M1.65373 3.12891C1.02532 3.9465 0.55148 4.88884 0.275574 5.91256H4.43739L1.65373 3.12891Z"
                fill="#0052B4"
              />
              <path
                d="M15.7244 5.91253C15.4485 4.88884 14.9747 3.9465 14.3463 3.12891L11.5627 5.91253H15.7244Z"
                fill="#0052B4"
              />
              <path
                d="M0.275574 10.0869C0.551511 11.1106 1.02535 12.0529 1.65373 12.8705L4.43729 10.0869H0.275574Z"
                fill="#0052B4"
              />
              <path
                d="M12.8705 1.65358C12.0529 1.02517 11.1106 0.551328 10.0869 0.275391V4.43717L12.8705 1.65358Z"
                fill="#0052B4"
              />
              <path
                d="M3.12939 14.3461C3.94699 14.9745 4.88933 15.4483 5.91302 15.7242V11.5625L3.12939 14.3461Z"
                fill="#0052B4"
              />
              <path
                d="M5.91299 0.275391C4.8893 0.551328 3.94696 1.02517 3.12939 1.65355L5.91299 4.43714V0.275391Z"
                fill="#0052B4"
              />
              <path
                d="M10.087 15.7242C11.1107 15.4483 12.053 14.9745 12.8706 14.3461L10.087 11.5625V15.7242Z"
                fill="#0052B4"
              />
              <path
                d="M11.5626 10.0869L14.3462 12.8705C14.9746 12.053 15.4485 11.1106 15.7244 10.0869H11.5626Z"
                fill="#0052B4"
              />
              <path
                d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z"
                fill="#D80027"
              />
              <path
                d="M10.087 10.0869L13.6569 13.6568C13.821 13.4927 13.9777 13.3211 14.1271 13.1432L11.0708 10.0869H10.087V10.0869Z"
                fill="#D80027"
              />
              <path
                d="M5.91305 10.0869H5.91298L2.34314 13.6568C2.50726 13.8209 2.67886 13.9776 2.85673 14.127L5.91305 11.0706V10.0869Z"
                fill="#D80027"
              />
              <path
                d="M5.91293 5.91274V5.91268L2.34305 2.34277C2.17887 2.5069 2.02224 2.67849 1.8728 2.85637L4.92915 5.91271L5.91293 5.91274Z"
                fill="#D80027"
              />
              <path
                d="M10.087 5.9132L13.6569 2.34327C13.4928 2.17908 13.3212 2.02245 13.1433 1.87305L10.087 4.92939V5.9132Z"
                fill="#D80027"
              />
            </g>
            <defs>
              <clipPath id="clip0_2465_26815">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {{ $t(`locale.${sLocale}`) }}
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Tr from '@/i18n/translation';

const { locale } = useI18n();
const supportedLocales = Tr.supportedLocales;
const router = useRouter();

const isDropdownVisible = ref(false);
const currentLocale = ref(locale.value);

const otherLocales = computed(() =>
  supportedLocales.filter((lang) => lang !== currentLocale.value)
);

function toggleDropdown() {
  isDropdownVisible.value = !isDropdownVisible.value;
}

async function switchLanguage(sLocale) {
  currentLocale.value = sLocale;
  isDropdownVisible.value = false;

  await Tr.switchLanguage(sLocale);
  try {
    await router.replace({ params: { locale: sLocale } });
  } catch (e) {
    console.log(e);
    await router.push('/');
  }
}
</script>

<style scoped>
.language-switcher {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #212121;
  width: 56px;
  height: 24px;
  justify-content: space-between;
}

.language-button {
  background: transparent;
  border: none;
  width: 100%;
  padding: 4px;
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.language-button span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 12px;
  background: #212121;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 56px;
  height: 24px;
}

.language-dropdown button {
  width: 100%;
  background: transparent;
  border: none;
  padding: 4px;
}

.language-dropdown button span {
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.rotated {
  transform: rotate(180deg);
}
</style>
