import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      user_id: false,
      username: 'guest', //--не залогинен
      auth_key: 'B89M5gVTJbiMZW-0gHsblJ-6-4yt7aw7',
      email: '',
      role: {
        item_name: 'guest',
      },
      name: '',
      profile: {},
      cart_id: null,

      isLogged: false,
    },
    products: [],
    kategoryes: [],
    favorites: [],
    cartCount: 0,
    isMobile: false,
    apiUrl: 'https://apilum.webtm.ru/web/index.php?r=',
    apiDomain: 'https://apilum.webtm.ru',
    flash_messsage: {
      title: '',
      massage: '',
      type: '',
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserData(state, user) {
      if (user.id) {
        state.user.user_id = user.id;
      }
      if (user.username) {
        state.user.username = user.username;
      }
      if (user.auth_key) {
        state.user.auth_key = user.auth_key;
      }
      if (user.tg) {
        state.user.tg = user.tg;
      }
      if (user.email) {
        state.user.email = user.email;
      }
      if (user.role) {
        state.user.role = user.role;
      }
      if (user.profile) {
        state.user.profile = user.profile;
      }
    },
    setProducts(state, products) {
      state.products = products;
    },
    setKategoryes(state, kategoryes) {
      state.kategoryes = kategoryes;
    },
    setFavorites(state, favorites) {
      state.favorites = favorites;
    },
    setCount(state, cartCount) {
      state.cartCount = cartCount;
    },
    setLogged(state, logged) {
      state.user.isLogged = logged;
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setFlashMessage(state, flashMessage) {
      state.flash_messsage = flashMessage;
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getIsMobile: (state) => {
      return state.isMobile;
    },
    getApiUrl: (state) => {
      return state.apiUrl;
    },
    getApiDomain: (state) => {
      return state.apiDomain;
    },
    getFlashMessage: (state) => {
      return state.flash_messsage;
    },
    getProducts: (state) => {
      return state.products;
    },
    getCount: (state) => {
      return state.cartCount;
    },
    getKategoryes: (state) => {
      return state.kategoryes;
    },
    getFavorites: (state) => {
      return state.favorites;
    },
  },
});
