<script>
import Tr from '@/i18n/translation';
import axios from 'axios';

export default {
  name: 'FooterBlock',
  data() {
    return {
      links: [],
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      Tr: Tr,
    };
  },
  computed: {
    localeUp() {
      return this.$i18n.locale;
    },
  },
  watch: {
    localeUp() {
      this.getFooter();
    },
  },
  mounted() {
    this.getFooter();
  },
  methods: {
    async getFooter() {
      const authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      try {
        const response = await axios.get(
          `${this.apiUrl}api/footer-menu/list-get-all${authGet}&locale=${this.$i18n.locale}`
        );
        console.log('footer-menu', response.data);
        this.links = response.data.list.map((f, index) => ({
          id: index + 1,
          name: f.name,
          url: f.link || '', // Основная ссылка
          subLinks: f.items || [], // Подссылки
        }));
        console.log('links', this.links);
      } catch (error) {
        console.error('Ошибка загрузки меню футера:', error);
      }
    },
    getLinkComponent(link) {
      if (
        this.isExternalLink(link) ||
        this.isEmailLink(link) ||
        this.isPhoneLink(link)
      ) {
        return 'a';
      }
      return 'router-link';
    },
    isExternalLink(link) {
      return (
        link.url?.startsWith('http://') || link.url?.startsWith('https://')
      );
    },
    isEmailLink(link) {
      return link.url?.startsWith('mailto:');
    },
    isPhoneLink(link) {
      return link.url?.startsWith('tel:');
    },
    isI18nRoute(link) {
      return (
        typeof link.url === 'string' &&
        !this.isExternalLink(link) &&
        !this.isEmailLink(link) &&
        !this.isPhoneLink(link)
      );
    },
    // Разбирает сложные маршруты с параметрами
    parseComplexRoute(url) {
      if (url.includes('?')) {
        const [name, queryString] = url.split('?');
        const query = Object.fromEntries(
          new URLSearchParams(queryString).entries()
        );
        return { name, query };
      }
      if (url.includes('/')) {
        const [name, id] = url.split('/');
        return { name, params: { id } };
      }
      return { name: url, query: {} };
    },
    getRouterLinkProps(link) {
      if (this.isI18nRoute(link)) {
        if (link.url.includes('?')) {
          const parsedRoute = this.parseComplexRoute(link.url);
          return Tr.i18nRoute(parsedRoute);
        }
        if (link.url.includes('/')) {
          const parsedRoute = this.parseComplexRoute(link.url);
          return Tr.i18nRoute(parsedRoute);
        }
        return Tr.i18nRoute({ name: link.url });
      }
      return null;
    },
    getLinkClass(link) {
      return this.isExternalLink(link) ||
        this.isEmailLink(link) ||
        this.isPhoneLink(link)
        ? 'sublink'
        : 'sublink';
    },
  },
};
</script>

<template>
  <div class="sector">
    <div class="footer">
      <div class="container-footer">
        <svg
          class="triangle"
          width="56.000000"
          height="54.000000"
          viewBox="0 0 56 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs />
          <path
            id="Vector"
            d="M56 0L56 54L54.5607 52.6092L53.6722 51.7562L38.5505 37.1714L14.6242 14.1027L1.67921 1.62259L0.71965 0.695404L0 0L56 0Z"
            fill="#FFFFFF"
            fill-opacity="1.000000"
            fill-rule="nonzero"
          />
        </svg>
        <img
          class="topVector absoluteVector"
          src="@/assets/image/Top (Desktop HD).svg"
          alt=""
        />
        <img
          class="leftVector absoluteVector"
          src="@/assets/image/Left (Desktop HD).svg"
          alt=""
        />
        <img
          class="rightVector absoluteVector"
          src="@/assets/image/Right (Desktop HD).svg"
          alt=""
        />
        <img
          class="bottomVector absoluteVector"
          src="@/assets/image/Bottom (Desktop HD).svg"
          alt=""
        />
        <div class="footer__content">
          <div class="logo">
            <img class="logo-image" src="@/assets/image/logo.svg" alt="" />
            <div class="footer__date">2024</div>
          </div>
          <div v-if="links.length > 0" class="nav-links">
            <div v-for="link in links" :key="link.id" class="col">
              <!-- <component
                :is="getLinkComponent(link)"
                :to="getRouterLinkProps(link)"
                :href="
                  isExternalLink(link) || isEmailLink(link) || isPhoneLink(link)
                    ? link.url
                    : null
                "
                :class="getLinkClass(link)"
              >
                {{ link.name }}
              </component> -->
              <span :class="getLinkClass(link)">
                {{ link.name }}
              </span>
              <div class="sublinks">
                <component
                  :is="getLinkComponent(sublink)"
                  v-for="sublink in link.subLinks"
                  :key="sublink.id"
                  :to="getRouterLinkProps(sublink)"
                  :href="
                    isExternalLink(sublink) ||
                    isEmailLink(sublink) ||
                    isPhoneLink(sublink)
                      ? sublink.url
                      : null
                  "
                  :class="getLinkClass(sublink)"
                >
                  <t
                    v-if="
                      sublink.icon &&
                      sublink.icon !== null &&
                      sublink.icon !== 'null'
                    "
                    :id="'sublink' + sublink.id"
                  ></t>
                  {{ sublink.name }}
                </component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="descriptionBlock">
      <div class="container">
        <div class="description__links">
          <div class="politics"></div>
          <a href="https://creatin.ru" class="creatin">
            <p class="creatin__text">разработано</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="15"
              viewBox="0 0 65 15"
              fill="none"
            >
              <path
                d="M6.47952 14.1329C3.36011 14.1329 0.828125 11.5139 0.828125 8.2968C0.828125 4.91475 3.5019 2.50195 6.47952 2.50195C8.28229 2.50195 9.94328 3.40933 10.8548 4.74978L8.80895 6.77075C8.32281 5.80151 7.28976 5.38906 6.49978 5.38906C5.08186 5.38906 3.66395 6.50267 3.66395 8.2968C3.66395 9.92595 4.91982 11.2458 6.49978 11.2458C7.51257 11.2458 8.44434 10.6477 8.8292 9.84347L10.8548 11.8644C9.96353 13.2255 8.28229 14.1329 6.47952 14.1329Z"
                fill="white"
              />
              <path
                d="M12.0586 14.1335V3.31142H14.7346V4.56557C15.2376 3.59462 16.7869 3.25074 18.0746 3.31142V6.14337C16.4448 5.86017 14.8755 6.89181 14.8755 8.59097V14.1335H12.0586Z"
                fill="white"
              />
              <path
                d="M24.5078 14.1342C21.1418 14.1342 18.877 11.2666 18.877 8.49923C18.877 5.61158 20.8509 2.88435 24.2377 2.90441C27.9363 2.92446 30.5128 5.7319 29.4738 9.74253H22.056C22.2015 10.7051 23.3858 11.6275 24.7987 11.6275C25.5675 11.6476 26.8973 11.3067 27.2714 10.8053L29.4738 12.4296C28.3518 13.6529 26.5441 14.1342 24.5078 14.1342ZM22.0352 7.3161H26.6272C26.7311 6.59418 26.1286 5.37094 24.3416 5.37094C22.804 5.37094 22.0352 6.45381 22.0352 7.3161Z"
                fill="white"
              />
              <path
                d="M35.7306 14.0535C32.6236 14.0535 30.1055 11.5535 30.1055 8.46883C30.1055 5.4043 32.6236 2.9043 35.7306 2.9043C38.8172 2.9043 41.3353 5.4043 41.3353 8.46883V14.1342H38.4923V12.8035C37.8628 13.5495 36.8678 14.0535 35.7306 14.0535ZM35.7306 11.2309C37.2536 11.2309 38.4923 10.0011 38.4923 8.46883C38.4923 6.95673 37.2536 5.72689 35.7306 5.72689C34.1872 5.72689 32.9485 6.95673 32.9485 8.46883C32.9485 10.0011 34.1872 11.2309 35.7306 11.2309Z"
                fill="white"
              />
              <path
                d="M48.5545 14.1336C46.275 14.1336 42.7248 13.5218 42.7248 9.56556V6.01714H41.7363V3.16209H42.7248V0.898438H45.5488V3.16209H48.1712V6.01714H45.5488V8.93337C45.5488 11.0747 47.1223 11.2786 48.5545 11.2786V14.1336Z"
                fill="white"
              />
              <path
                d="M50.1602 14.1335V3.30469H52.9676V14.1335H50.1602Z"
                fill="white"
              />
              <path
                d="M54.1699 14.1342V8.44711C54.1699 5.19148 56.4904 2.9043 59.5843 2.9043C62.6579 2.9043 64.9987 5.19148 64.9987 8.44711V14.1342H62.149V8.44711C62.149 6.77809 61.1109 5.78903 59.5843 5.78903C58.0577 5.78903 57.0196 6.77809 57.0196 8.44711V14.1342H54.1699Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer {
  background: var(--Black-1300, #1d1d1b);
  height: 367px;
  padding: 30px;
}
.footer__content {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  height: 100%;
}
.logo {
  display: flex;
  align-items: flex-end;
  gap: 54px;
  align-self: flex-end;
}
.logo-image {
  transform: translateY(8px);
}
.nav-links {
  display: flex;
  gap: 116px;
}
.col {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.boss__link {
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sublinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sublink {
  color: var(--Black-400, #d9d9d9);
  font-family: 'Suisse Intl';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}
.sublink:nth-last-of-type(1),
.sublink:nth-last-of-type(2) {
  white-space: inherit;
}
.footer__date {
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 17px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.triangle {
  position: absolute;
  top: 0;
  right: 0;
}
.container-footer {
  max-width: 1860px;
  padding: 43px 186px 7px 0px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.absoluteVector {
  position: absolute;
  z-index: 2;
  object-fit: cover;
}
.topVector {
  top: 0;
  left: 0px;
  max-width: calc(100% - 99px);
  height: 1px;
}
.leftVector {
  top: 0;
  left: 0;
  max-height: calc(100% - 146.24px);
  width: 1px;
}
.rightVector {
  bottom: 0;
  right: 0;
  max-height: calc(100% - 96.5px);
  width: 1px;
}
.bottomVector {
  bottom: 0;
  right: 0;
  max-width: calc(100% - 34%);
  height: 1px;
}
.descriptionBlock {
  background: #0e0e0e;
  padding: 16px 0;
}
.description__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.politics {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.politic__link {
  color: #6f6f6f;
  font-family: 'Suisse Intl';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}
.creatin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}
.creatin__text {
  color: var(--Whiteboard-600, #979797);
  font-family: Jeko-SemiBold;
  font-size: 7.883px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1690px) {
  .nav-links {
    gap: 75px;
  }
  .logo-image {
    width: 70%;
  }
}
@media (max-width: 1440px) {
  .container-footer {
    padding: 43px 100px 7px 0px;
  }

  .footer__date {
    font-size: 14px;
  }
  .bottomVector {
    height: 1px;
  }
}
@media (max-width: 1180px) {
  .head-h1 svg {
    width: 283px;
    height: 47.56px;
  }
  .triangle {
    width: 30px;
    height: 30px;
  }
  .topVector {
    max-width: calc(100% - 63px);
    height: 1px;
  }
  .rightVector {
    bottom: 0;
    right: 0;
    max-height: calc(100% - 63px);
    width: 1px;
  }
  .head-h1 {
    transform: translate(0px, 0px);
  }
  .container {
    padding: 20px;
  }
  .contacts {
    gap: 16px;
  }

  .nav-links {
    gap: 44px;
  }
  .container-footer {
    padding: 27px 20px 7px 0px;
  }
  .boss__link {
    font-size: 14px;
  }
  .sublink {
    font-size: 13px;
    white-space: inherit;
  }
  .logo-image {
    width: 253px;
  }
  .footer__date {
    display: none;
  }
  .logo {
    gap: 24px;
  }
  .bottomVector {
    max-width: calc(100% - 275.77px);
  }
  .footer {
    height: auto;
  }
}
@media screen and (max-width: 650px) {
  .footer__content {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .logo {
    align-self: flex-start;
  }
  .col:nth-of-type(2) {
    order: 1;
    width: 100%;
  }
  .col:nth-of-type(1) {
    order: 2;
    width: calc(50% - 5px);
  }
  .col:nth-of-type(3) {
    order: 3;
    width: calc(50% - 5px);
  }
  .nav-links {
    flex-wrap: wrap;
    gap: 50px 10px;
    justify-content: space-between;
  }
  .footer {
    height: auto;
    padding: 17.52px 10px;
  }
  .col:nth-of-type(2) .sublinks {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .col:nth-of-type(2) .sublink {
    width: calc(50% - 5px);
  }
  .container-footer {
    padding: 40px 12px 0;
  }
  .logo-image {
    transform: translate(-17px, 1px);
  }
  .leftVector {
    max-height: calc(100% - 58.24px);
  }

  .boss__link {
    font-size: 24px;
  }
  .sublink {
    font-size: 15px;
  }
}
</style>
