<script>
export default {
  name: 'SearcheLink',
  props: {
    className: {
      type: String,
      default: '',
    },
    status: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['goSearch'],
  data() {
    return {
      boolStatus: false,
    };
  },
  watch: {
    status(newStatus) {
      this.boolStatus = newStatus;
    },
  },
  mounted() {
    this.boolStatus = this.status;
  },
  methods: {
    goSearch() {
      this.boolStatus = !this.boolStatus;
      this.$emit('goSearch', this.boolStatus);
    },
  },
};
</script>
<template>
  <div
    :class="className.name + ' ' + className.hovered + ' search-icon'"
    @click="goSearch"
  >
    <svg
      v-if="!boolStatus"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 21L16.7 16.7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style scoped>
.search-icon {
  cursor: pointer;
}
.white path {
  stroke: black;
}
.black path,
.true path {
  stroke: white;
}
@media screen and (max-width: 650px) {
  .search-icon {
    display: none;
  }
}
</style>
