<script>
export default {
  name: 'HomeNewProducts',
  data() {
    return {
      windowWidth: window.innerWidth,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  computed: {
    products() {
      return this.$store.getters.getProducts.filter(
        (product) => product.new == 1
      ); // Фильтруем товары по новинкам
      // return this.$store.getters.getProducts;
    },
    filteredProducts() {
      if (this.windowWidth < 650) {
        return this.products.slice(0, 5);
      }
      if (this.windowWidth < 1024) {
        return this.products.slice(0, 6);
      }
      return this.products;
    },
  },
  mounted() {
    console.log('products', this.products);

    // Слушаем изменения ширины окна
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    // Удаляем слушатель при уничтожении компонента
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    truncate(text, maxLength) {
      let mobile = window.innerWidth < 1024;
      if (mobile) {
        maxLength = 28;
      }
      if (!text) return '';
      return text.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text;
    },
  },
};
</script>

<template>
  <div v-if="filteredProducts.length > 0" class="new-products sector">
    <div class="container">
      <div class="new-products__content">
        <div class="new-products__head">
          <div class="head-h3">
            {{ $t('home.new-product.title') }}
          </div>
        </div>
        <div class="new-products__items">
          <router-link
            v-for="(product, index) in filteredProducts.slice(0, 9)"
            :key="product.id"
            :to="{
              name: 'ProductPage',
              params: { id: product?.group?.group_id || product?.id },
              query: { p: product?.group?.group_id ? undefined : 'true' },
            }"
            class="new-products__item"
            :class="[
              { big: index === 5 },
              { 'some-condition-class': product.class },
            ]"
          >
            <div class="new-products__item-img">
              <img
                :src="apiDomain + '/web/uploads/' + product.productPhoto[0]"
                :alt="product.name"
                loading="lazy"
              />
              <div class="tag">NEW</div>
            </div>
            <div :title="product.name" class="head-h4">
              {{ truncate(product.name, 50) }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.new-products__content {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.new-products__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.new-products__item {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.new-products__item.big {
  grid-column: span 2;
  grid-row: 2 / 4;
}
.new-products__item:hover img {
  transition: all 0.3s;
  transform: scale(1);
}
.new-products__item-img {
  position: relative;
  min-height: 360px;
  background: var(--Black-300, #eaeaea);
  overflow: hidden;
  padding: 8px;
  height: 100%;
}
.new-products__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: all 0.3s;
}
.tag {
  color: var(--Black, #111);
  font-family: 'Suisse Intl';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 10px;
  border: 1px solid var(--Black-1000, #545454);
  position: relative;
  z-index: 1;
  height: max-content;
  width: max-content;
  background: #fff;
}
.head-h4 {
  color: var(--Black, #111);
  font-family: 'Suisse Intl';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1440px) {
  .new-products__item-img {
    min-height: 301px;
  }
}
@media screen and (max-width: 1024px) {
  .new-products__items {
    grid-template-columns: repeat(3, 1fr);
  }
  .new-products__item-img {
    min-height: 228px;
  }
}
@media screen and (max-width: 650px) {
  .new-products__items {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  .new-products__item {
    gap: 8px;
  }
  .new-products__item-img {
    min-height: 163px;
  }
  .new-products__item.big {
    grid-row-start: 2;
    grid-row-end: 4;
  }
  .tag {
    font-size: 10px;
    padding: 5px 8px;
  }
}
</style>
