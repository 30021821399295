<script>
import Tr from '@/i18n/translation';
import axios from 'axios';

export default {
  name: 'HomeMainBlock',
  data() {
    return {
      Tr,
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      slides: [],
      currentIndex: 0,
      isSlideScrollComplete: false,
      isScrolling: false,
      progressBars: [],
      i: 0,
      progressAnimation: null,
      previousTimestamp: null,
      dur: null,
    };
  },
  computed: {
    localeUp() {
      return this.$i18n.locale;
    },
  },
  watch: {
    localeUp() {
      this.getBanners();
    },
  },
  mounted() {
    this.getBanners();
  },
  beforeUnmount() {
    this.stopProgress();
  },
  methods: {
    getScreenWidth() {
      return window.screen.width;
    },
    getVideoUrlByDeviceWidth(slide) {
      return this.getScreenWidth() < 769
        ? slide.video_m || slide.video || ''
        : slide.video || '';
    },
    getSlideStyle(imgUrl) {
      return {
        background: `url(${this.apiDomain}/web/uploads/${imgUrl}) no-repeat`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      };
    },
    slideTop(index) {
      return index * 100;
    },
    progressWidth(index) {
      return index === this.currentIndex ? this.i + '%' : '0%';
    },
    setupVideoEvents(videoElement) {
      if (!videoElement || !(videoElement instanceof HTMLVideoElement)) {
        console.error('Provided element is not a video element:', videoElement);
        return;
      }

      // Сбрасываем видео на начало
      videoElement.currentTime = 0;

      const playVideo = () => {
        videoElement
          .play()
          .then(() => {
            console.log('Video playback started');
            this.dur = videoElement.duration;
            this.isVideoPlaying = true;
          })
          .catch((err) => {
            console.error('Error starting video playback:', err);
          });
      };

      if (videoElement.readyState >= 1) {
        console.log('Video is already loaded');

        playVideo();
      } else {
        console.log('Waiting for video to load');

        videoElement.addEventListener('loadedmetadata', playVideo, false);
      }

      // Обработка завершения видео
      videoElement.addEventListener('ended', () => {
        console.log('Video ended');
        this.dur = 7;
        this.isVideoPlaying = false;
        this.nextSlide();
        this.startProgress();
      });
    },
    progress(timestamp) {
      const currentSlide = this.slides[this.currentIndex];

      const videoElement = document.getElementById(
        'video-' + this.currentIndex
      );

      // Если текущий слайд содержит видео
      if (currentSlide.video && videoElement) {
        console.log('SDFSDF');

        if (!this.isVideoPlaying) {
          this.setupVideoEvents(videoElement);
          return; // Ждём запуска видео
        }
        return; // Пока видео не закончится, прогресс не идёт
      }
      this.dur = 7;
      // Для обычных слайдов
      if (!this.previousTimestamp) {
        this.previousTimestamp = timestamp;
      }
      const elapsed = timestamp - this.previousTimestamp;

      if (elapsed > 100) {
        this.i += (100 / (this.dur * 1000)) * elapsed; // Рассчитываем прогресс
        this.previousTimestamp = timestamp;

        if (this.i >= 100) {
          this.i = 0;
          this.nextSlide();
        }
      }

      this.progressAnimation = requestAnimationFrame(this.progress);
    },
    startProgress() {
      this.stopProgress(); // Очищаем предыдущий прогресс
      this.previousTimestamp = null;
      this.progressAnimation = requestAnimationFrame(this.progress);
    },
    stopProgress() {
      if (this.progressAnimation) {
        cancelAnimationFrame(this.progressAnimation);
        this.progressAnimation = null;
      }
    },
    nextSlide(index = false) {
      if (index !== false) {
        this.currentIndex = index;

        this.i = 0;
        this.startProgress();
        return;
      }
      if (this.currentIndex < this.slides.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0; // Циклический переход
      }
      this.i = 0; // Сбрасываем прогресс
    },
    async getBanners() {
      const params = `&auth=${this.user.username}:${this.user.auth_key}`;
      try {
        const response = await axios.get(
          `${this.apiUrl}api/banner/get-banner-home${params}&locale=${this.$i18n.locale}`
        );
        console.log('response', response);

        this.slides = response.data.banner.map((item) => {
          if (item.video === 'null') item.video = null;
          if (item.video_m === 'null') item.video_m = null;
          return item;
        });

        this.progressBars = document.querySelectorAll('.progress-bar__item');
        this.startProgress();
      } catch (error) {
        console.error('Ошибка при получении баннеров:', error);
      }
    },
  },
};
</script>

<template>
  <div
    ref="slideWrapper"
    class="home-main slide-wrapper"
    @wheel="progressScrollG"
  >
    <div class="slide-slider">
      <div
        v-for="(slide, index) in slides"
        :key="slide.id"
        class="slide"
        :style="
          slide.video && currentIndex === index
            ? { position: 'relative', width: '100%' }
            : getSlideStyle(slide.pic, index)
        "
        :class="{ active: currentIndex === index }"
      >
        <video
          v-if="slide.video"
          :id="`video-${index}`"
          :key="slide.id + slide.video"
          autoplay
          playsinline
          muted
          preload="auto"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
          "
          :poster="apiDomain + `/web/uploads/${slide.pic}`"
          :src="apiDomain + `/web/uploads/${getVideoUrlByDeviceWidth(slide)}`"
        ></video>
        <div
          class="container"
          :style="slide.video ? { position: 'relative', zIndex: 2 } : {}"
        >
          <div class="home-main__content">
            <h2 class="head-h2">
              {{ slide.title }}
            </h2>
            <p class="sub-head-h2">
              {{ slide.subtitle }}
            </p>
            <a
              v-if="slide.link && slide.text_btn"
              :href="slide.link"
              class="btn-opacity"
            >
              {{ slide.text_btn }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M5.25 5.25H12.75M12.75 5.25V12.75M12.75 5.25L5.25 12.75"
                  stroke="white"
                  stroke-width="1.35"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="progress-bar container">
      <div
        v-for="(slide, index) in slides"
        :key="'progress' + index"
        class="progress-bar__item"
        :class="{ active: currentIndex === index }"
        @click="nextSlide(index)"
      >
        <div
          v-if="dur"
          class="line"
          :style="{
            animation:
              currentIndex === index
                ? `progressAnimation ${dur}s linear forwards`
                : '',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-wrapper {
  background: #1e1d1c;
  overflow: hidden;
  position: relative;
}
.slide-slider {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 1.5s all;
  z-index: 1;
  opacity: 0.5;
  bottom: -100vh;
  transform: scale(0.8);
  transition: 1.5s all;
}
.slide.active {
  z-index: 2;
  bottom: 0;
  opacity: 1;
  transform: scale(1);
  transition: 1.5s all;
}
.container {
  height: 100%;
}
.home-main__content {
  display: flex;
  flex-direction: column;
  padding-bottom: 194px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 36px;
  height: 100%;
}
.head-h2 {
  max-width: 996px;
}
.sub-head-h2 {
  max-width: 795px;
}
.btn-opacity {
  border: 1px solid var(--White, #fff);
  padding: 16.5px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 288px;
  margin-top: 12px;
}
.progress-bar {
  justify-content: space-between;
  display: flex;
  gap: 15px;
  bottom: 25px;
  position: absolute;
  width: 100%;
  z-index: 2;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
}
.progress-bar__item {
  width: 100%;
  height: 2px;
  display: block;
  background: #616161;
  position: relative;
  cursor: pointer;
}
.progress-bar__item .line {
  position: absolute;
  background: #616161;
  height: 2px;
  width: 100%;
  left: 0;
  top: 0;
  content: '';
}
.progress-bar__item.active .line {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 0; /* Начальная ширина для анимации */
  background-color: white; /* Цвет активного прогресс бара */
}

@media screen and (max-width: 1024px) {
  .slide-slider {
    max-height: 455px;
  }
  .home-main__content {
    padding-bottom: 50px;
    gap: 24px;
  }
  .btn-opacity {
    font-size: 12px;
    padding: 12px;
    width: 184px;
  }
  .head-h2,
  .sub-head-h2 {
    max-width: 454px;
  }
}
@media screen and (max-width: 650px) {
  .slide-slider {
    max-height: none;
  }
  .home-main__content {
    justify-content: flex-end;
    padding-bottom: 100px;
  }
}
</style>
