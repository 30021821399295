<script>
import Tr from '@/i18n/translation';
export default {
  name: 'AccountLink',
  props: {
    // eslint-disable-next-line
    className: {
      name: String,
      hovered: Boolean,
    },
  },
  emits: ['logout'],
  data() {
    return {
      Tr: Tr,
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      openModal: false,
    };
  },
  methods: {
    accountEmit() {
      if (this.user.role.item_name === 'guest') {
        this.$router.push({ name: 'Login' });
      } else {
        if (this.openModal) {
          this.openModal = false;
        } else {
          this.openModal = true;
        }
      }
      console.log(this.user.role.item_name);
    },
    logout() {
      this.$emit('logout');
    },
  },
};
</script>
<template>
  <div class="accountIconWrp">
    <a
      :class="className.name + ' ' + className.hovered + ' account-icon'"
      @click="accountEmit"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 20C18 18.4087 17.3679 16.8826 16.2426 15.7574C15.1174 14.6321 13.5913 14 12 14M12 14C10.4087 14 8.88258 14.6321 7.75736 15.7574C6.63214 16.8826 6 18.4087 6 20M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
          stroke="white"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>

    <div v-if="openModal" class="modal-ACC">
      <div class="modal-ACC-content">
        <router-link
          :to="Tr.i18nRoute({ name: 'Account' })"
          class="lk-open link-modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 12.8889C14.4162 12.8889 16.375 10.899 16.375 8.44444C16.375 5.98985 14.4162 4 12 4C9.58375 4 7.625 5.98985 7.625 8.44444C7.625 10.899 9.58375 12.8889 12 12.8889ZM12 12.8889C13.8565 12.8889 15.637 13.6381 16.9497 14.9717C18.2625 16.3053 19 18.114 19 20M12 12.8889C10.1435 12.8889 8.36301 13.6381 7.05025 14.9717C5.7375 16.3053 5 18.114 5 20"
              stroke="white"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t('headerNav.account.acclink') }}</router-link
        >

        <router-link
          :to="Tr.i18nRoute({ name: 'Wishlist' })"
          class="link-modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18.3 13.7778C19.641 12.48 21 10.9244 21 8.88889C21 7.59228 20.4785 6.34877 19.5502 5.43192C18.6219 4.51508 17.3628 4 16.05 4C14.466 4 13.35 4.44444 12 5.77778C10.65 4.44444 9.534 4 7.95 4C6.63718 4 5.37813 4.51508 4.44982 5.43192C3.52152 6.34877 3 7.59228 3 8.88889C3 10.9333 4.35 12.4889 5.7 13.7778L12 20L18.3 13.7778Z"
              stroke="white"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t('headerNav.account.wishlist') }}
        </router-link>
        <a class="link-modal red" @click="logout">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9M16 17L21 12M21 12L16 7M21 12H9"
              stroke="#E22C28"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t('headerNav.account.exit') }}
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.white path {
  stroke: black;
}
.black path,
.true path {
  stroke: white;
}
.modal-ACC {
  transition: all 0.3s;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  max-width: 239px;
  background: #2c2c2a;
  padding: 20px 24px;
  z-index: 6;
  margin: 0;
}
.modal-ACC-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: max-content;
}
.link-modal {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--White, #fff);
  font-family: 'Suisse Intl';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%;
}
.link-modal path {
  stroke: #fff;
}
.link-modal.red {
  color: #e22c28;
}
.link-modal.red path {
  stroke: #e22c28;
}
.accountIconWrp {
  position: relative;
}
</style>
