<script>
export default {
  name: 'AccountLink',
  props: {
    // eslint-disable-next-line
    className: {
      name: String,
      hovered: Boolean,
    },
  },
  emits: ['openKategoryes'],
  methods: {
    openKategoryes() {
      this.$emit('openKategoryes');
    },
  },
};
</script>
<template>
  <div
    :class="className.name + ' ' + className.hovered + ' account-icon burger'"
    @click="openKategoryes"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 12H21"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 6H21"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 18H21"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style scoped>
.white path {
  stroke: black;
}
.black path,
.true path {
  stroke: white;
}
.burger {
  display: none;
}

@media (max-width: 1024px) {
  .burger {
    display: block;
  }
}
</style>
