<script>
import HeaderBlock from '../block/HeaderBlock.vue';
import HomeMainBlock from '../block/HomeMainBlock.vue';
import HomeProducts from '../block/HomeProducts.vue';
import HomeSliderBlock from '../block/HomeSliderBlock.vue';
import HomeNewProducts from '../block/HomeNewProducts.vue';
import HomeSpecialBlock from '../block/HomeSpecialBlock.vue';
import HomeActionBlock from '../block/HomeActionBlock.vue';
import HomeNewsBlock from '../block/HomeNewsBlock.vue';
import FooterBlock from '../block/FooterBlock.vue';

export default {
  name: 'HomePage',
  components: {
    HeaderBlock,
    HomeMainBlock,
    HomeProducts,
    HomeSliderBlock,
    HomeNewProducts,
    HomeSpecialBlock,
    HomeActionBlock,
    HomeNewsBlock,
    FooterBlock,
  },
  data() {
    return {};
  },
};
</script>

<template>
  <HeaderBlock></HeaderBlock>
  <HomeMainBlock></HomeMainBlock>
  <HomeProducts></HomeProducts>
  <HomeSliderBlock></HomeSliderBlock>
  <HomeNewProducts></HomeNewProducts>
  <HomeSpecialBlock></HomeSpecialBlock>
  <HomeActionBlock></HomeActionBlock>
  <HomeNewsBlock></HomeNewsBlock>
  <FooterBlock></FooterBlock>
</template>
