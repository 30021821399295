import { createI18n } from 'vue-i18n';
import pluralRules from './rules/pluralization';
import ru from './locales/ru.json';
import en from './locales/en.json';

export default createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  legacy: false,
  globalInjection: true,
  pluralRules,
  messages: {
    ru,
    en,
  },
});
